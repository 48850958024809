.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.contentContainer {
  background-color: white;
  border: none;
}

.sectionDiv {
  background-color: white;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: Roboto, sans-serif;
  font-style: bold;
}

p {
  font-family: LinuxLibertine, serif;
  font-size: large;
}

li {
  font-family: LinuxLibertine, serif;
  font-size: large;
}

.pageCard {
  border: none !important;
  text-align: center;
}

.alertsTitle {
  display: inline-block;
  background-color: maroon;
  color: white;
  padding-right: 0.25vw;
  padding-left: 0.25vw;
}

.recLink {
  color: black;
  text-decoration: none;
}

.recLink:focus {
  color: black;
}
.feesTable {
  font-size: small;
}