.lowerNavbarDiv {
    background-color: black;
    color: white;
    font-family: LinuxLibertine, serif;
}

.lowerNavbar {
    color: white;
}

.lowerNavbarBrand {
    color: white;
}

.navbarItem {
    color: white;
}

.lowerNavbarLinks {
    margin-left:auto;
}

.lowerNavbarDropdown {
    margin-right:auto;
    margin-left: none;
}

.customLowerNavbarItem {
    margin-left: 1vw;
    text-decoration: none;
    font-size: large;
    color: white;
    text-align: center;
}