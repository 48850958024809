.banner {
    height: 50vh;
    width: 50vw;
    margin: auto;

}

.bannerContainer {
    margin: auto;
    width: 100%;
}

.bannerImage {
    background-image: url('https://ik.imagekit.io/4lalrpwz0sy/BannerImage_Y7a6x6kBJ.jpg?ik-sdk-version=javascript-1.4.3&updatedAt=1662793916586');
    height: 0;
    background-size: cover;
    padding-top: 50%;
    display: flex;
}

.welcomeMessage {
    color: white;
}

.welcomeWrapper {
    align-self: flex-end;
    width: 100%;
    background-color: rgba(47, 79, 79, 0.73);
    margin-bottom: 0;
}

.rowContainer {
    text-align: none;
}

.bannerLink {
    color: white;
    text-decoration: none;
}

.bannerLink:hover {
    color: white;
}