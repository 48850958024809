.customNavbar {
    background-color: black;
    color: white;
}

.navbarLogo {
    margin-left: 1vw;
}

.customNavbarItem {
    margin-left: 1vw;
    text-decoration: none;
    font-size: large;
    color: black;
}

.customNavbar {
    padding-right: 1rem;
}

.customDropdown {
    margin-left: auto;
    justify-content: end;
    font-size: large;
    font-family: Kitten-Slant, serif;
}

.websiteName {
    font-family: Roboto, sans-serif;
    font-size: x-large;
    margin-top: 1vh;
}
.upperNavbarLink {
    color: white;
    text-decoration: none;
}

.upperNavbarLink:hover {
    color: white;
}